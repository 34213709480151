@use 'styles/includes' as *;

.SavingsCalculator {
    $root: &;
    margin: 12rem 0;

    &__Container {
        @extend %container;
    }

    &__Title {
        margin: 0 0 2rem;
        font-family: $font-family-bold;
        font-size: 2.4rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        letter-spacing: -0.048rem;

        @include media(xxl) {
            margin: 0 0 4.8rem;
            font-size: 4.8rem;
            letter-spacing: -0.096rem;
        }
    }

    &__Comparison {
        margin: 0 0 4rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

    &__Item {
        display: grid;
        gap: 0.2rem;
    }

    &__ItemTitle {
        font-family: $font-family-bold;
        font-size: 1rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.8rem;

        @include media(xxl) {
            font-size: 1.6rem;
        }

        &::before {
            content: '';
            width: 0.8rem;
            height: 0.8rem;
            background-color: $violet;
            border-radius: 100%;
            display: inline-block;
        }

        &--Violet {
            &::before {
                background-color: $violet;
            }
        }

        &--Grey80 {
            &::before {
                background-color: $grey-80;
            }
        }

        &--Grey75 {
            &::before {
                background-color: $grey-75;
            }
        }
    }

    &__AmountTitle {
        font-family: $font-family-bold;
        font-size: 4rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
    }

    &__Amount {
        font-family: $font-family-bold;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        line-height: 100%;

        @include media(xxl) {
            font-size: 4.8rem;
        }
    }

    &__Text {
        font-family: $font-family-bold;
        font-size: 1rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        color: $grey-60;

        @include media(xxl) {
            font-size: 1.6rem;
        }
    }

    &__Slider {
        position: relative;
    }

    &__SliderContent {
        font-family: $font-family-bold;
        font-size: 2rem;
        font-weight: $font-weight-bold;
        letter-spacing: -0.04rem;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2rem;

        @include media(xxl) {
            font-size: 3.8rem;
            letter-spacing: -0.076rem;
        }
    }

    &__SliderTitle {
        padding: 0.4rem;
    }

    &__SliderValue {
        padding: 0 1rem 0 0;
        width: 100%;
        font-family: $font-family-bold;
        font-size: 2rem;
        font-weight: $font-weight-bold;
        letter-spacing: -0.04rem;
        color: $grey-60;

        background-color: $grey-20;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media (hover: hover) {
            &:hover {
                background-color: #e6e6e6;
            }
        }
    }

    &__SliderButton,
    &__InputField {
        padding: 0;
        width: 100%;
        font-family: $font-family-bold;
        font-size: 2rem;
        font-weight: $font-weight-bold;
        letter-spacing: -0.04rem;
        color: $grey-60;
        text-align: right;
        border: 0;
        background-color: transparent;

        @include media(xxl) {
            font-size: 3.8rem;
            letter-spacing: -0.076rem;
        }
    }

    &__InputRange {
        margin: 2.2rem 0;
        width: 100%;
        height: 0.1rem;
        background-color: $grey-20;
        background-image: linear-gradient($black, $black);
        background-repeat: no-repeat;
        -webkit-appearance: none;
        appearance: none;

        &::-webkit-slider-thumb {
            width: 4.6rem;
            height: 2rem;
            background-color: $white;
            border: 0.1rem solid $black;
            border-radius: 2rem;
            background-image: url('/img/range-control.svg');
            background-size: 3rem 1.4rem;
            background-position: center;
            background-repeat: no-repeat;
            appearance: none;
            -webkit-appearance: none;
            transition: $transition-ease-out-expo background-color;
            cursor: pointer;

            @include media(xxl) {
                width: 6.2rem;
                height: 3.2rem;
                background-size: 3.6rem 1.6rem;
            }

            @media (hover: hover) {
                &:hover {
                    background-color: $black;
                    background-image: url('/img/range-control-white.svg');
                }
            }
        }
    }

    &__Suffix {
        font-family: $font-family-bold;
        font-size: 2rem;
        font-weight: $font-weight-bold;
        letter-spacing: -0.04rem;
        color: $grey-60;

        @include media(xxl) {
            font-size: 3.8rem;
            letter-spacing: -0.076rem;
        }
    }

    &__Bottom {
        margin: 2rem 0 0;
    }

    &__BottomGrid {
        display: grid;
        gap: 1rem;

        @include media(m) {
            gap: 3.2rem;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__LargeAmount {
        font-family: $font-family-bold;
        font-size: 6rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        letter-spacing: -0.12rem;
        display: inline-block;

        @include media(m) {
            margin: 0 0 2rem;
        }

        @include media(xxl) {
            margin: 0 0 4rem;
            font-size: 10rem;
            letter-spacing: -0.22rem;
            white-space: nowrap;
        }
    }

    &__Disclaimer {
        p {
            font-family: $font-family-regular;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: $font-weight-regular;
            line-height: 130%;
            letter-spacing: -0.024rem;
            color: $grey-70;

            &:not(:last-child) {
                margin-bottom: 1.6rem;
            }

            @include media(m) {
                order: 0;
            }

            @include media(xxl) {
                font-size: 1.6rem;
                letter-spacing: -0.32px;
            }
        }

        a {
            text-decoration: underline;
            text-underline-offset: 2px;

            @media (hover: hover) {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__LargeText {
        margin: 0 0 3rem;
        font-family: $font-family-bold;
        font-size: 2.4rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        letter-spacing: -0.048rem;

        @include media(xxl) {
            font-size: 4.8rem;
            letter-spacing: -0.096rem;
        }

        span {
            color: $grey-50;
        }
    }

    &__ButtonContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include media(m) {
            order: 1;
        }
    }

    &__Button {
        padding: 2rem 3.2rem;
        width: 100%;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 1.6rem;
        line-height: 100%;
        text-align: center;
        color: $white;
        background-color: $black;
        border-radius: 0.4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        gap: 1.2rem;
        position: relative;
        z-index: 0;
        transition: color $transition-ease-out-expo;
        overflow: hidden;

        @include media(m) {
            padding: 2.2rem 3.2rem;
            width: auto;
            font-size: 2rem;
        }

        &::after {
            content: '';
            width: 0;
            height: 100%;
            background-color: $violet;
            position: absolute;
            inset: 0;
            z-index: -1;
            transition: width $transition-ease-out-expo;
        }

        @media (hover: hover) {
            &:hover {
                color: $black;

                &::after {
                    width: 100%;
                }
            }
        }
    }
}
